import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import AuthLayout from "layouts/AuthLayout";
import GettingStartedLayout from "layouts/GettingStartedLayout";
import PrivateRoute from "./PrivateRoute";
import DashboardLayout from "layouts/DashboardLayout";
import NProgress from "nprogress";
// import history from "utils/history";

const LazyLoad = () => {
    React.useEffect(() => {
        NProgress.start();
        return () => {
            NProgress.done();
        };
    });

    return "";
};
//Referencing pages
//Auths..
const Signup = lazy(() => import("pages/auth/Signup"));
const VerifyEmail = lazy(() => import("pages/auth/VerifyEmail"));
const VerifyNameChange = lazy(() => import("pages/auth/VerifyNameChange"));
const Login = lazy(() => import("pages/auth/Login"));
const ForgotPassword = lazy(() => import("pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("pages/auth/ResetPassword"));

//Getting started
const PlanDetails = lazy(() => import("pages/auth/gettingStarted/PlanDetails"));
const ChoosePlan = lazy(() => import("pages/auth/gettingStarted/ChoosePlan"));

//Home
const Home = lazy(() => import("pages/app/home/Home"));
const Explore = lazy(() => import("pages/app/explore/Explore"));
const AccountSetup = lazy(() => import("pages/app/home/AccountSetup"));
const BoltAccountSetup = lazy(() => import("pages/app/home/bolt/AccountSetup"));
const PersonalInfo = lazy(() => import("pages/app/home/PersonalInfo"));

const PreHomeBolt = lazy(() => import("pages/app/home/bolt/PreHome"));
const RenewalForm = lazy(() => import("pages/app/home/RenewalForm"));
const BenefitsPage = lazy(() => import("pages/app/home/Benefits"));
const IndividualBenefits = lazy(() =>
    import("pages/app/home/IndividualBenefit")
);

//Details
const HealthInsurance = lazy(() =>
    import("pages/app/benefitDetails/HealthInsurance")
);
const gymInsurance = lazy(() => import("pages/app/benefitDetails/Gym"));
const HealthBeneficiary = lazy(() =>
    import("pages/app/benefitDetails/HealthBeneficiary")
);
const HealthBeneficiaryDetail = lazy(() =>
    import("pages/app/benefitDetails/HealthBeneficiaryDetail")
);
const HealthBeneficiaryForm = lazy(() =>
    import("pages/app/benefitDetails/HealthBeneficiaryForm")
);
const HealthClaim = lazy(() => import("pages/app/makeClaim/HealthClaim"));
const PhoneClaim = lazy(() => import("pages/app/makeClaim/DeviceClaim"));

const SavingsInvestment = lazy(() =>
    import("pages/app/benefitDetails/Savings")
);

const PhoneInsurance = lazy(() =>
    import("pages/app/benefitDetails/PhoneInsurance")
);

//Settings
const Settings = lazy(() => import("pages/app/settings/Settings"));
const Error404 = lazy(() => import("pages/404"));

//Wallets
const WalletTransaction = lazy(() =>
    import("pages/app/wallet/WalletTransaction")
);

const baseName = process.env.PUBLIC_URL;

//Creating Routes
const Routes = () => {
    return (
        <>
            <Router basename={baseName}>
                <Suspense fallback={<LazyLoad />}>
                    <Switch>
                        {/* can't access them when you are logged in */}
                        {/* <AuthRoute
                            exact
                            path="/"
                            component={Login}
                            layout={AuthLayout}
                        /> */}

                        <AuthRoute
                            exact
                            path="/login"
                            component={Login}
                            layout={AuthLayout}
                        />
                        <AuthRoute
                            exact
                            path="/signup"
                            component={Signup}
                            layout={AuthLayout}
                        />
                        <AuthRoute
                            exact
                            path="/verifyemail"
                            component={VerifyEmail}
                            layout={AuthLayout}
                        />
                        <AuthRoute
                            exact
                            path="/Verifyname"
                            component={VerifyNameChange}
                            layout={AuthLayout}
                        />

                        <AuthRoute
                            exact
                            path="/forgot-password"
                            component={ForgotPassword}
                            layout={AuthLayout}
                        />

                        <AuthRoute
                            exact
                            path="/reset-password"
                            component={ResetPassword}
                            layout={AuthLayout}
                        />

                        <AuthRoute
                            exact
                            path="/signup/plan-details/:id"
                            component={PlanDetails}
                            layout={GettingStartedLayout}
                            isLogin={true}
                            isSignup={false}
                        />

                        <AuthRoute
                            exact
                            path="/signup/choose-plan"
                            component={ChoosePlan}
                            layout={GettingStartedLayout}
                            isLogin={false}
                            isSignup={true}
                        />

                        {/* can only access them when you are logged in */}

                        {/* Home */}
                        <PrivateRoute
                            exact
                            path="/"
                            component={Home}
                            layout={DashboardLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/app/explore"
                            component={Explore}
                            layout={DashboardLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/app/explore/plan-details/:id"
                            component={PlanDetails}
                            layout={DashboardLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/app/account-setup"
                            component={AccountSetup}
                            layout={DashboardLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/app/bolt/account-setup"
                            component={BoltAccountSetup}
                            layout={DashboardLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/app/account-setup/personal"
                            component={PersonalInfo}
                            layout={DashboardLayout}
                        />

                        {/* Bolt home */}
                        <PrivateRoute
                            exact
                            path="/awaiting-activation"
                            component={PreHomeBolt}
                            layout={DashboardLayout}
                            navClassName="preBoltNav"
                            extraClassName="boltPreHomeMain"
                        />

                        {/* Renewal form */}
                        <PrivateRoute
                            exact
                            path="/renew"
                            component={RenewalForm}
                            layout={DashboardLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/bolt-deals"
                            component={PreHomeBolt}
                            layout={DashboardLayout}
                            navClassName="preBoltNav"
                            extraClassName="boltPreHomeMain"
                        />

                        {/* Benefit Details */}
                        <PrivateRoute
                            exact
                            path="/app/benefit/health-insurance/:benefitId/:id"
                            component={HealthInsurance}
                            layout={DashboardLayout}
                        />
                        <PrivateRoute
                            exact
                            path="/app/benefit/gym/:benefitId/:id"
                            component={gymInsurance}
                            layout={DashboardLayout}
                        />
                        <PrivateRoute
                            exact
                            path="/app/benefit/health-beneficiary/:primaryUserBenefitId/:id"
                            component={HealthBeneficiary}
                            layout={DashboardLayout}
                        />
                        <PrivateRoute
                            exact
                            path="/app/benefit/health-beneficiary/details/:beneficiaryId/:benefitId"
                            component={HealthBeneficiaryDetail}
                            layout={DashboardLayout}
                        />
                        <PrivateRoute
                            exact
                            path="/app/benefit/:primaryUserBenefitId/:id/add-health-beneficiary"
                            component={HealthBeneficiaryForm}
                            layout={DashboardLayout}
                        />

                        {/* <PrivateRoute
                            exact
                            path="/app/benefit/make-a-claim-health"
                            component={HealthClaim}
                            layout={DashboardLayout}
                        /> */}
                        <PrivateRoute
                            exact
                            path="/app/benefit/make-a-claim-phone/:id"
                            component={PhoneClaim}
                            layout={DashboardLayout}
                        />
                        <PrivateRoute
                            exact
                            path="/app/benefit/phone/:id"
                            component={PhoneInsurance}
                            layout={DashboardLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/app/benefit/savings/:benefitId/:id"
                            component={SavingsInvestment}
                            layout={DashboardLayout}
                        />

                        {/* Settings */}
                        <PrivateRoute
                            exact
                            path="/app/settings"
                            component={Settings}
                            layout={DashboardLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/app/settings/:tabs"
                            component={Settings}
                            layout={DashboardLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/app/benefits"
                            component={BenefitsPage}
                            layout={DashboardLayout}
                        />

                        <PrivateRoute
                            exact
                            path="/app/benefits/:id"
                            component={IndividualBenefits}
                            layout={DashboardLayout}
                        />

                        {/*Wallet*/}
                        <PrivateRoute
                            exact
                            path="/transactions"
                            component={WalletTransaction}
                            layout={DashboardLayout}
                        />
                        {/* catch all invalid urls */}
                        <Route path="*" component={Error404} />
                    </Switch>
                </Suspense>
            </Router>
        </>
    );
};

export default Routes;
