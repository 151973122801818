import React, { useEffect, useState } from "react";
import DashboardHeader from "components/DashboardHeader";
import { UserPlanContext, UserDetailContext } from "context";
import { getBenefits } from "services/benefits";
import NProgress from "nprogress";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

const DashboardLayout = withRouter(
    ({ navClassName, history, extraClassName, children }) => {
        const [userPlan, setUserPlan] = useState(null);
        const [plan, setPlan] = useState(null);
        const [userDetail, setUserDetail] = useState(null);

        const getUserPlan = async () => {
            try {
                const response = await getBenefits();
                setUserPlan(response.data);
                setPlan(response.data?.userPlanSubscription);
                NProgress.done();
            } catch (error) {
                NProgress.done();
                if (error?.response?.status === 404) {
                    setUserPlan(null);
                }
                return error;
            }
        };
        useEffect(() => {
            const user = JSON.parse(localStorage.getItem("user"));
            if (user) {
                const userDetail = {
                    id: user.id,
                    email: user.username,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    hasActivePlan: user.hasActivePlan,
                };
                setUserDetail(userDetail);
                getUserPlan();
            }
        }, []);

        return (
            <div>
                <UserDetailContext.Provider
                    value={{ userDetail, setUserDetail }}
                >
                    <UserPlanContext.Provider value={{ userPlan, getUserPlan }}>
                        <DashboardHeader navClassName={navClassName} />
                        <section
                            className={`${
                                plan?.daysToPlanExpiry === -1 &&
                                history.location.pathname === "/"
                                    ? "add-banner"
                                    : ""
                            }`}
                        >
                            {history.location.pathname === "/" &&
                            plan?.daysToPlanExpiry > 0 &&
                            plan?.daysToPlanExpiry <= 10 ? (
                                <div className="renewal-banner">
                                    <p>
                                        Your {plan?.planName} will expire in{" "}
                                        {plan?.daysToPlanExpiry} days. Please
                                        renew to continue to stay covered!
                                    </p>
                                    <Link className="btn btn--sm" to="/renew">
                                        Pay now!
                                    </Link>
                                </div>
                            ) : plan?.daysToPlanExpiry < 0 ? (
                                <div className="renewal-banner">
                                    <p>
                                        Your {plan?.planName} has expired.
                                        Please renew to continue to stay
                                        covered!
                                    </p>
                                    <Link className="btn btn--sm" to="/renew">
                                        Pay now!
                                    </Link>
                                </div>
                            ) : (
                                ""
                            )}
                            <main className={`main ${extraClassName}`}>
                                <section className="main-container">
                                    {children}
                                </section>
                            </main>
                        </section>
                    </UserPlanContext.Provider>
                </UserDetailContext.Provider>
            </div>
        );
    }
);

export default DashboardLayout;
