import React, { useState, useContext } from "react";
import { UserDetailContext } from "context";
import Logo from "assets/svgs/Flance-Logo__Full.svg";
import Icon from "components/Icon";
import AuthService from "../services/auth";
import { withRouter, Link } from "react-router-dom";

const DashboardHeader = ({ ...props }) => {
    const { userDetail } = useContext(UserDetailContext);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const logout = () => {
        localStorage.removeItem("userPlan");
        localStorage.removeItem("user");
        localStorage.removeItem("gymInfo");
        props.history.push("/login");
        AuthService.logout()
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    const [openDropdown, setDropdown] = useState(false);
    return (
        <>
            <nav className={`topnav ${props.navClassName}`}>
                <div className="topnav--wrapper">
                    <div className="brand">
                        <img src={Logo} alt="Flance Logo" />
                    </div>

                    <div
                        className="topnav-right d-iflx al-i-c"
                        onMouseOver={(e) => setDropdown(true)}
                        onMouseLeave={(e) => setDropdown(false)}
                    >
                        <div className="avatar-circle">
                            {/* <div className="image-wrapper">
                            <img
                                src=""
                                alt="profile picture"
                            />
                        </div> */}
                            <div className="avatar-circle__placeholder iflx-center-center">
                                <p className="avatar-circle__initials iflx-center-center">
                                    {(userDetail?.firstname
                                        ? userDetail.firstname[0]
                                        : "") +
                                        (userDetail?.lastname
                                            ? userDetail?.lastname[0]
                                            : "")}
                                </p>
                            </div>
                        </div>
                        <div className="d-iflx al-i-c">
                            <p className="username mr-8">
                                {userDetail?.firstname}
                            </p>
                            <Icon width="8px" height="5px" id="drop-icon" />

                            {openDropdown && (
                                <ul className="topnav__dropdown">
                                    {user?.partner === "bolt" &&
                                    !user?.hasActivePlan ? (
                                        ""
                                    ) : (
                                        <li>
                                            <Link to="/app/settings">
                                                Settings
                                            </Link>
                                        </li>
                                    )}

                                    <li onClick={logout}>
                                        <span className="logoutBtn">
                                            Logout
                                        </span>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default withRouter(DashboardHeader);
