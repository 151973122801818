import React from "react";
import { Route, Redirect } from "react-router-dom";
import propTypes from "prop-types";

const PrivateRoute = ({
    component: Component,
    layout: Layout,
    navClassName,
    extraClassName,
    pageTitle,
    ...rest
}) => {
    const isAuthenticated = JSON.parse(localStorage.getItem("user"));
    return (
        <Route
            {...rest}
            render={(props) => {
                return (
                    <main>
                        <Layout extraClassName={extraClassName} navClassName={navClassName}>
                            {isAuthenticated && isAuthenticated.jwt ? (
                                <Component {...props} />
                            ) : (
                                <Redirect exact push to="/login" />
                            )}
                        </Layout>
                    </main>
                );
            }}
        />
    );
};

PrivateRoute.propTypes = {
    component: propTypes.object.isRequired,
};

export default PrivateRoute;
