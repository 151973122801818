import { analytics } from "utils/segmentUtils";

import api from "./api";
import axios from "axios";

export async function submitKyc(payload) {
    try {
        const res = await api.post("kycs/submit", payload);
        return res;
    } catch (err) {
        throw err;
    }
}
export async function updateName(payload) {
    try {
        const res = await api.patch("users/name/initiate", payload);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function updateHospitalKyc(payload) {
    try {
        const res = await api.put("kycs/axa/hospital/update", payload);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function getBenefits() {
    try {
        const res = await api.get("benefits/user");
        return res;
    } catch (err) {
        throw err;
    }
}

export async function getBenefitDetail(id) {
    try {
        const res = await api.get(`benefit/${id}`);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function getUserBenefitDetails(id, isBusinessBenefit) {
    const isTrueSet = isBusinessBenefit === "true";
    try {
        const res = await api.get(
            `benefits/user/${id}${
                isTrueSet === true
                    ? "?isBusinessBenefit=" + isBusinessBenefit
                    : ""
            }`
        );
        return res;
    } catch (err) {
        throw err;
    }
}
// export async function getUserBenefitDetail(id) {
//     try {
//         const res = await api.get(`benefits/user/${id}`);
//         return res;
//     } catch (err) {
//         throw err;
//     }
// }
export async function getProviderDetail(id) {
    try {
        const res = await api.get(`provider/branches/${id}`);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function activateBenefit(payload) {
    try {
        const res = await api.post(`benefit/activation`, payload);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function getBenefitTransactionHistory(id) {
    try {
        const res = await api.get(`benefit/transaction/history/${id}`);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function getFlexSavingsTransactionHistory() {
    try {
        const res = await api.get(`wallet/transactions`);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function verifyImei(payload) {
    try {
        const res = await axios.get(
            "https://kelpom-imei-checker1.p.rapidapi.com/api",
            {
                params: { ...payload },
                headers: {
                    "X-RapidAPI-Key":
                        "93ae20f7d0msh522d7e32281a854p1b656fjsn496f3afe20eb",
                    "X-RapidAPI-Host": "kelpom-imei-checker1.p.rapidapi.com",
                },
            }
        );
        return res;
    } catch (err) {
        throw err;
    }
}

export async function submitDeviceInfo(payload) {
    try {
        const res = await api.post(
            `benefits/user/profile/${payload.benefitId}`,
            payload
        );
        return res;
    } catch (err) {
        throw err;
    }
}

export async function downloadCertificate(id) {
    try {
        const res = await api.get(`benefits/certificate/${id}`);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function makeClaim(benefitId) {
    try {
        const res = await api.post(`benefits/user/claims/${benefitId}`);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function fetchClaimStatus(id) {
    try {
        const res = await api.get(`benefits/user/claims/status/${id}`);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function getBenefitById(id) {
    try {
        const res = await api.get(`kycs/${id}`);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function loanPrequalification(payload) {
    try {
        const res = await api.post(`kycs/loan/qualification`, payload);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function purchaseBenefit(payload) {
    try {
        const res = await api.post(`purchase/benefit`, payload);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function confirmBenefitPayment(payload) {
    try {
        const res = await api.post(
            `purchase/benefit/payment/notification`,
            payload
        );
        return res;
    } catch (err) {
        throw err;
    }
}

// export async function getUserBenefitDetails(benefitId) {
//     try {
//         const res = await api.get(`benefits/user/${benefitId}`);
//         return res;
//     } catch (err) {
//         throw err;
//     }
// }

export async function planRenewal(payload) {
    try {
        const res = await api.post(`purchase/plan/renewal`, payload);
        return res;
    } catch (err) {
        throw err;
    }
}
export async function benefitPayment(payload) {
    const userDetail = JSON.parse(localStorage.getItem("user"));
    try {
        analytics.track({
            userId: userDetail?.id.toString(),
            event: "Purchase_Benefit",
            properties: {
                button_text: `Continue to pay ${payload?.amount}`,
                duration: payload?.duration,
                amount: payload?.amount,
            },
        });
        const res = await api.post(`purchase/benefit`, payload);
        return res;
    } catch (err) {
        throw err;
    }
}
export async function changeBenefitPayment(payload, id, isBusinessBenefit) {
    try {
        const res = await api.post(
            `benefit/change/subscription/${id}?isBusinessBenefit=${
                isBusinessBenefit ? "true" : "false"
            }`,
            payload
        );
        return res;
    } catch (err) {
        throw err;
    }
}
export async function changeBenefitPaymentWithDiscount(
    payload,
    id,
    isBusinessBenefit
) {
    const userDetail = JSON.parse(localStorage.getItem("user"));
    try {
        analytics.track({
            userId: userDetail?.id.toString(),
            event: "Purchase_Benefit",
            properties: {
                button_text: `Continue to pay ${payload?.amount}`,
                duration: payload?.duration,
                amount: payload?.amount,
            },
        });
        const res = await api.post(
            `benefit/change/subscription/assign/${id}?isBusinessBenefit=${
                isBusinessBenefit ? "true" : "false"
            }`,
            payload
        );
        return res;
    } catch (err) {
        throw err;
    }
}
export async function changeBenefitPaymentWithWallet(
    payload,
    id,
    isBusinessBenefit
) {
    const userDetail = JSON.parse(localStorage.getItem("user"));
    try {
        analytics.track({
            userId: userDetail?.id.toString(),
            event: "Purchase_Benefit",
            properties: {
                button_text: `Continue to pay ${payload?.amount}`,
                duration: payload?.duration,
                amount: payload?.amount,
            },
        });
        const res = await api.post(
            `benefit/change/subscription/withWallet/${id}?isBusinessBenefit=${
                isBusinessBenefit ? "true" : "false"
            }`,
            payload
        );
        return res;
    } catch (err) {
        throw err;
    }
}

export async function discountBenefitPayment(payload) {
    const userDetail = JSON.parse(localStorage.getItem("user"));
    try {
        analytics.track({
            userId: userDetail?.id.toString(),
            event: "Purchase_Benefit",
            properties: {
                button_text: `Continue to pay ${payload?.amount}`,
                duration: payload?.duration,
                amount: payload?.amount,
            },
        });
        const res = await api.post(`purchase/benefit/assign`, payload);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function topUpSavings(payload) {
    try {
        const res = await api.post(`wallet/savings/fund`, payload);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function payBenefitWithToken(payload) {
    try {
        const response = await api.post("purchase/benefit/withToken", payload);
        return response;
    } catch (error) {
        throw error;
    }
}

export async function planRenewalWithToken(payload) {
    try {
        const response = await api.post(
            "purchase/plan/renewal/withToken",
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export async function planRenewalWithWallet(payload) {
    try {
        const response = await api.post(
            "purchase/plan/renewal/withWallet",
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}
export async function benefitPaymentWithWallet(payload) {
    const userDetail = JSON.parse(localStorage.getItem("user"));
    try {
        analytics.track({
            userId: userDetail?.id.toString(),
            event: "Purchase_Benefit",
            properties: {
                button_text: `Continue to pay ${payload?.amount}`,
                duration: payload?.duration,
                amount: payload?.amount,
            },
        });
        const response = await api.post("purchase/benefit/withWallet", payload);
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getSignedUrl() {
    try {
        const res = await api.get(`kycs/signed-url/jpg`);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function uploadToAws(signedUrl, selfieFormData) {
    try {
        const res = await axios.put(signedUrl, selfieFormData.get("File"));
        return res;
    } catch (err) {
        throw err;
    }
}

export async function getAllBenefits() {
    try {
        const res = await api.get(`benefit?isCorporate=false&size=1000`);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function createSavingsBenefit() {
    try {
        const response = await api.post("wallet/savings/create");
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getSavings() {
    try {
        const response = await api.get("wallet/savings/get");
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getAllStates() {
    try {
        const res = await api.get("benefit/states");
        return res;
    } catch (err) {
        throw err;
    }
}
export async function getAllgasForState(payload) {
    try {
        const res = await api.get(`benefit/states/${payload}/lgas`);
        return res;
    } catch (err) {
        throw err;
    }
}
export async function getAllBenefitsByCategory(payload) {
    try {
        const res = await api.get(
            `provider/branches/category/${payload}?page=0&size=1000`
        );
        return res;
    } catch (err) {
        throw err;
    }
}
export async function getAllBenefitsByCategoryByLga(payload) {
    try {
        const res = await api.get(`provider/branches/category/GYM/${payload}`);
        return res;
    } catch (err) {
        throw err;
    }
}
export async function getAllGymBenefits(payload) {
    try {
        const res = await api.get(
            `provider/branches/category/GYM?page=0&size=1000&amenities=${payload?.amenities.join(
                ","
            )}&activities=${payload?.activities.join(
                ","
            )}&equipment=${payload?.equipment.join(
                ","
            )}&lgaIds=${payload?.lgaIds.join(",")}&stateId=${
                payload?.state ? payload?.state : ""
            }`
        );
        return res;
    } catch (err) {
        throw err;
    }
}
export async function getAllBenefitsByCategoryByState(payload) {
    try {
        const res = await api.get(
            `provider/branches/category/GYM/state/${payload}`
        );
        return res;
    } catch (err) {
        throw err;
    }
}

export async function pauseGymBenefits(id, isBusinessBenefit) {
    try {
        const res = await api.post(
            `benefit/pause/${id}?isBusinessBenefit=${
                isBusinessBenefit ? "true" : "false"
            }`
        );
        return res;
    } catch (err) {
        throw err;
    }
}
export async function resumeGymBenefits(id, isBusinessBenefit, payload) {
    try {
        const res = await api.post(
            `benefit/resume/${id}?isBusinessBenefit=${
                isBusinessBenefit ? "true" : "false"
            }`,
            payload
        );
        return res;
    } catch (err) {
        throw err;
    }
}

export async function createAutoPay(payload) {
    try {
        const response = await api.post(
            "wallet/savings/auto-contribute/enable",
            payload
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getAllBenefitsByCategoryByMuiltipleLga(payload) {
    try {
        const ids = payload.map((item) => item.id);
        const res = await api.get(
            `provider/branches/category/lgas/GYM/${ids.join(",")}`
        );
        return res;
    } catch (err) {
        throw err;
    }
}
export async function getAllBenefitsByCategoryBySingleLga(payload) {
    try {
        const res = await api.get(
            `provider/branches/category/lgas/GYM/${payload}`
        );
        return res;
    } catch (err) {
        throw err;
    }
}
export async function getAllGymFeatures() {
    try {
        const res = await api.get(`provider/branches/features`);
        return res;
    } catch (err) {
        throw err;
    }
}
