import React from "react";
import { Link } from "react-router-dom";
import Logo from "assets/svgs/Flance-Logo__Full.svg";
import Notif1 from "assets/images/notif1.png";
import Notif2 from "assets/images/notif2.png";
import PlanCard from "assets/images/plan-card.png";
import { createBrowserHistory } from "history";

import bestBody from "assets/images/gym-logos/bestBody.png";
import cbaGym from "assets/images/gym-logos/cbaGym.png";
import donsylvester from "assets/images/gym-logos/donSylvester.png";
import doris from "assets/images/gym-logos/doris.png";
import fitnessArcade from "assets/images/gym-logos/fitnessArcade.png";
import fitnessOption from "assets/images/gym-logos/fitnessOption.png";
import fresbon from "assets/images/gym-logos/frebson.png";
import greenLeaf from "assets/images/gym-logos/greenLeaf.png";
import mancave from "assets/images/gym-logos/manCave.png";
import sansFrontier from "assets/images/gym-logos/sansFrontier.png";
import topfit from "assets/images/gym-logos/topfit.png";
import tripleA from "assets/images/gym-logos/tripleA.png";
import LogoSlider from "components/Slider";

const AuthLayout = ({ children }) => {
    let search = createBrowserHistory().location.search;
    let params = new URLSearchParams(search);
    let partner = params.get("partner");
    let product = params.get("product");

    return (
        <div className={`auth`}>
            <div className="auth__aside">
                <div className="auth__aside--content">
                    <div className="brand">
                        {partner === "bolt" ? (
                            <a
                                href={`${process.env.REACT_APP_FLANCEWEBSITE}/deals/bolt`}
                            >
                                <img src={Logo} alt="Flance Logo" />
                            </a>
                        ) : partner === "pagesbydami" ? (
                            <a
                                href={`${process.env.REACT_APP_FLANCEWEBSITE}/communities/pagesbydami`}
                            >
                                <img src={Logo} alt="Flance Logo" />
                            </a>
                        ) : partner === "gym" ? (
                            <a
                                href={`${process.env.REACT_APP_FLANCEWEBSITE}/products/gym`}
                            >
                                <img src={Logo} alt="Flance Logo" />
                            </a>
                        ) : (
                            <a href={`${process.env.REACT_APP_FLANCEWEBSITE}`}>
                                <img src={Logo} alt="Flance Logo" />
                            </a>
                        )}
                    </div>
                    {product === "gym" ? (
                        <>
                            <>
                                <div className="auth__aside--header">
                                    <h1>
                                        Find and book a top gym near you
                                        starting at N15k/month.
                                    </h1>
                                    <p>
                                        With Flance, you get access to a massive
                                        network of gyms, studios, and fitness
                                        centers.
                                    </p>
                                </div>
                                <div className="auth__aside--images">
                                    <div className="carousel__container primary">
                                        <div className="mini__carousel">
                                            <LogoSlider
                                                images={[
                                                    cbaGym,
                                                    bestBody,
                                                    donsylvester,
                                                    doris,
                                                    fitnessArcade,
                                                    fitnessOption,
                                                    fresbon,
                                                    greenLeaf,
                                                    mancave,
                                                    sansFrontier,
                                                    topfit,
                                                    tripleA,
                                                ]}
                                            />
                                            <LogoSlider
                                                images={[
                                                    sansFrontier,
                                                    topfit,
                                                    tripleA,
                                                    cbaGym,
                                                    bestBody,
                                                    donsylvester,
                                                    doris,
                                                    fitnessArcade,
                                                    fitnessOption,
                                                    fresbon,
                                                    greenLeaf,
                                                    mancave,
                                                ]}
                                            />
                                            <LogoSlider
                                                images={[
                                                    fresbon,
                                                    greenLeaf,
                                                    mancave,
                                                    sansFrontier,
                                                    topfit,
                                                    tripleA,
                                                    cbaGym,
                                                    bestBody,
                                                    donsylvester,
                                                    doris,
                                                    fitnessArcade,
                                                    fitnessOption,
                                                ]}
                                            />
                                            <LogoSlider
                                                images={[
                                                    doris,
                                                    fitnessArcade,
                                                    fitnessOption,
                                                    fresbon,
                                                    greenLeaf,
                                                    mancave,
                                                    sansFrontier,
                                                    topfit,
                                                    tripleA,
                                                    cbaGym,
                                                    bestBody,
                                                    donsylvester,
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        </>
                    ) : (
                        <>
                            <div className="auth__aside--header">
                                <h1>
                                    All-in-one access to employment benefits.
                                </h1>
                                <p>
                                    Whether healthcare, pension, or savings, get
                                    affordable plans that help you save money,
                                    and live a fuller life.
                                </p>
                            </div>
                            <div className="auth__aside--images">
                                <div
                                    className="images"
                                    data-aos="fade-right"
                                    data-aos-offset="-50"
                                    // data-aos-duration="3000"
                                >
                                    <img
                                        data-aos="fade-left"
                                        data-aos-offset="0"
                                        data-aos-delay="550"
                                        src={Notif1}
                                        alt="notification"
                                        className="notif notif1"
                                    />

                                    <img
                                        data-aos="fade-right"
                                        data-aos-offset="0"
                                        data-aos-delay="650"
                                        src={Notif2}
                                        alt="notification"
                                        className="notif notif2"
                                    />

                                    <img
                                        src={PlanCard}
                                        alt="plan card"
                                        className="plancard"
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="auth__form">{children}</div>
        </div>
    );
};

export default AuthLayout;
