import React, { useEffect } from "react";
// import './App.css';
import Routes from "routes/Routes";
import AOS from "aos";
import "aos/src/sass/aos.scss";
import { Toaster } from "react-hot-toast";
import "nprogress/nprogress.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const App = () => {
    useEffect(() => {
        AOS.init({
            easing: "ease-out-back",
            once: false,
            offset: 120,
            duration: 1000,
            // mirror: true,
        });
    }, []);

    return (
        <div>
            <Routes />
            <Toaster
                position="top-right"
                toastOptions={{
                    duration: 4000,
                    success: {
                        style: {
                            background: "#EBF7EE",
                            zIndex: 999,
                        },
                    },
                    error: {
                        style: {
                            background: "#FFF3F3",
                            zIndex: 999,
                        },
                    },
                    style: {
                        borderRadius: "8px",
                        maxWidth: "366px",
                        minHeight: "48px",
                        boxShadow: "none",
                        fontFamily: "Inter",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "17px",
                        letterSpacing: "0.01em",
                        color: "#000B14",
                        textAlign: "left",
                        flex: "unset",
                        zIndex: 999,
                    },
                }}
            />
        </div>
    );
};

export default App;
